import createHome from "../Home";
import createAnalytics from "../Analytics";
import createSettings from "../Settings";
import createCalendar from "../Calendar";
import createMyCompany from "../MyCompany";
import createTutorial from "../Tutorial";
import createTenderModelTest from "../_Tender2";

let App;

export default store => ({
  path: "/app",
  getComponent(nextState, cb) {
    /** глобально inject части store для левой панели
     * и другие части store, необходимые для
     * повсеместного использования вроде history
     * необходимость - повсеместный доступ через сокеты
     * к определенным частям store
     */
    Promise.all([
      import("../../containers/AuthenticatedContainer"),
      import("../../containers/SocketContainer"),
      import("../../containers/RedirectToTutorialContainer"),
      import("../../store/reducers"),
      import("./containers/AppContainer"),
      import("@tenderplan3/store/modules/info"),
      import("@tenderplan3/store/modules/keys"),
      import("@tenderplan3/store/modules/marks"),
      import("@tenderplan3/store/modules/counts"),
      import("../../store/modules/history"),
      import("@tenderplan3/store/modules/tenders"),
      import("../../store/modules/popups"),
      import("../../store/modules/banks"),
      import("../../store/modules/api"),
      import("../../store/modules/telegram"),
      import("@tenderplan3/store/modules/products"),
      import("@tenderplan3/store/modules/comments"),
      import("@tenderplan3/store/modules/lockedRequests"),
      import("@tenderplan3/store/modules/tools"),
      import("../../store/modules/lawyers")
    ]).then(results => {
      const [
        wrapAuthentication,
        wrapSocket,
        wrapTutorialRedirect,
        reducers,
        app,
        info,
        keys,
        marks,
        counts,
        history,
        tenders,
        popups,
        banks,
        api,
        telegram,
        products,
        comments,
        lockedRequests,
        tools,
        lawyers
      ] = results;

      const { injectReducer } = reducers;

      // каждый раз когда меняется урл срабатывает этот метод,
      // в результате в App ссылка меняется, хотя компонент остается тот же
      // тут костыль, который не будет менять ссылку
      App =
        App ||
        wrapAuthentication.default(
          wrapSocket.default(wrapTutorialRedirect.default(app.default))
        );

      injectReducer(store, { key: "info", reducer: info.default });
      injectReducer(store, { key: "keys", reducer: keys.default });
      injectReducer(store, { key: "marks", reducer: marks.default });
      injectReducer(store, { key: "counts", reducer: counts.default });
      injectReducer(store, { key: "history", reducer: history.default });
      injectReducer(store, { key: "tenders", reducer: tenders.default });
      injectReducer(store, { key: "popups", reducer: popups.default });
      injectReducer(store, { key: "banks", reducer: banks.default });
      injectReducer(store, { key: "api", reducer: api.default });
      injectReducer(store, { key: "telegram", reducer: telegram.default });
      injectReducer(store, { key: "comments", reducer: comments.default });
      injectReducer(store, { key: "products", reducer: products.default });
      injectReducer(store, { key: "lawyers", reducer: lawyers.default });
      injectReducer(store, {
        key: "lockedRequests",
        reducer: lockedRequests.default
      });
      injectReducer(store, { key: "tools", reducer: tools.default });

      /*  Return getComponent   */
      cb(null, App);
    });
  },
  childRoutes: [
    createHome(store),
    createAnalytics(store),
    createSettings(store),
    createCalendar(store),
    createMyCompany(store),
    createTutorial(store),
    createTenderModelTest(store)
  ]
});
