import React from "react";
import PropTypes from "prop-types";
import Cookie from "js-cookie";
import flagsmith from "flagsmith";
import pick from "lodash/pick";

import { setUserId } from "../../helpers/metrics";
import { getBrowserTimezone } from "../../helpers/russianDateTime";
import handleCookies from "./helpers/cookies";

export default class CoreLayout extends React.Component {
  static childContextTypes = {
    history: PropTypes.object,
    location: PropTypes.object
  };

  getChildContext() {
    return {
      history: this.props.history,
      location: this.props.location
    };
  }

  componentDidMount() {
    flagsmith.startListening(300000);
    this.handleInitialization();

    this.props.router.listenBefore((location, unblock) => {
      if (location.pathname.startsWith("/app")) {
        unblock();
      } else {
        window.location.reload();
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.firm?.availableDateTime !== nextProps.firm?.availableDateTime
    ) {
      this.handlePaymentStateChange(nextProps);
    }

    if (!this.props.user && nextProps.user) {
      // user is authenticated and set, handle seo staff
      this.handleAuthenticated(nextProps);
    }

    if (
      !this.props.isInitialized &&
      nextProps.isInitialized &&
      !nextProps.isAuthenticated
    ) {
      // user hasn't authenticated and set, handle seo staff
      this.handleUnauthenticated(nextProps);
    }
  }

  handleInitialization = (
    {
      location,
      initialize,
      updateGlobals,
      authorizeToken,
      magiclink,
      replace
    } = this.props
  ) => {
    const { query } = location;

    if (location.pathname.startsWith("/external")) return;

    handleCookies(location);

    if (query.firm && typeof window.sessionStorage !== "undefined") {
      const impersonate = `${query.firm}&${query.mode || "view"}`;
      updateGlobals({ impersonate });
      window.sessionStorage.setItem("impersonate", impersonate);
    }

    const promo = Cookie.get("_tpxpm");
    if (promo) {
      updateGlobals({ discount: promo });
    }

    /* authorize via magic link */
    if (query.ml) {
      magiclink(query.ml)
        .then(
          res => {
            this.handleToken(res);
            return this.handleInfo();
          },
          () => {}
        )
        .then(() => {
          replace({
            pathname: location.pathname,
            query: {
              ...query,
              ml: undefined
            }
          });
          initialize();
        });
    } else {
      /* or validate current token */
      authorizeToken()
        .then(
          res => {
            this.handleToken(res);
            return this.handleInfo();
          },
          () => {}
        )
        .then(() => {
          initialize();
        });
    }
  };

  handleToken = res => {
    if (!localStorage) return;
    localStorage.setItem("token", `${res.token_type} ${res.access_token}`);
  };

  handleInfo = ({ fetchInfo, updateGlobals } = this.props) => {
    return fetchInfo().then(
      res => {
        setUserId(res.current);

        const user = res.users.find(v => v._id === res.current);
        updateGlobals({ admin: !!user?.admin });

        return flagsmith.identify(res.firm._id, {
          ...pick(res.firm, ["legacyId", "paymentState"]),
          is_active: res.firm.availableDateTime > Date.now()
        });
      },
      () => {}
    );
  };

  handleAuthenticated = (
    { user, firm, updateGlobals, updateUserTimezone } = this.props
  ) => {
    const browserTime = getBrowserTimezone();

    if (user.timeZone !== browserTime) {
      updateUserTimezone(browserTime);
    }

    if (firm.promos && firm.promos.current) {
      const discount = firm.promos.current;
      updateGlobals({ discount });
    }
  };

  handleUnauthenticated = () => {};

  handlePaymentStateChange = ({ firm, updateGlobals } = this.props) => {
    const paid = firm.availableDateTime > Date.now();
    updateGlobals({ paid });
  };

  render() {
    return this.props.children;
  }
}
