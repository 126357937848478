import { connect } from "react-redux";
import { push, replace } from "react-router-redux";

import {
  getInfoUser,
  fetchInfo,
  getFirm,
  updateUserTimezone
} from "@tenderplan3/store/modules/info";
import { authorizeToken, magiclink } from "@tenderplan3/store/modules/auth";
import { initialize, updateGlobals } from "../store/modules/globals";
import CoreLayout from "../layouts/CoreLayout";

const mapStateToProps = state => ({
  isInitialized: state.globals.initialized,
  isAuthenticated: state.auth.isAuthenticated,
  user: getInfoUser(state.info),
  firm: getFirm(state.info)
});

const mapActionCreators = {
  replace,
  initialize,
  authorizeToken,
  fetchInfo,
  updateGlobals,
  updateUserTimezone,
  magiclink,
  push
};

export default connect(
  mapStateToProps,
  mapActionCreators
)(CoreLayout);
