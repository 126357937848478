"use strict";

export default store => ({
  path: "tender2",
  getComponent(nextState, cb) {
    import("./_Tender2").then(component => {
      cb(null, component.default);
    });
  }
});
