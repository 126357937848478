import Cookie from "js-cookie";

import { YANDEX_METRICS_ID } from "../../helpers/metrics";

export const registration = () => {
  // Facebook
  window.fbq && window.fbq("track", "CompleteRegistration");
  // Yandex
  window.yaCounter24094609 && window.yaCounter24094609.reachGoal("reg");
  // GA 4
  window.dataLayer && window.dataLayer.push({ event: "registration" });
};

export const partnerRegistration = () => {
  window.yaCounter24094609 && window.yaCounter24094609.reachGoal("new_partner");

  window.dataLayer && window.dataLayer.push({ event: "registration_partner" });
};

export const saveLastRef = location => {
  const ref = location.search + location.hash;
  if (ref) {
    Cookie.set("ref", ref, { expires: 30 });
  }
};

export const setUserId = userId => {
  window.ym && window.ym(YANDEX_METRICS_ID, "setUserID", userId);
  window.ga && window.ga("set", "userId", userId);
  window.dataLayer &&
    window.dataLayer.push(["config", "G-QBM958ZHNC", { user_id: userId }]);
};
