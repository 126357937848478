import cookie from "js-cookie";

const retrievePromo = query => {
  if (query.promo) return query.promo;
  if (query.utm_campaign) return query.utm_campaign;
  return null;
};

const handleCookies = location => {
  if (document.referrer) {
    cookie.set("referer", document.referrer, {
      secure: process.env.NODE_ENV === "production",
      sameSite: "Lax",
      expires: new Date(Date.now() + 2592000000)
    });
  }

  if (location.search) {
    cookie.set("source", location.search.substring(1), {
      secure: process.env.NODE_ENV === "production",
      sameSite: "Lax",
      expires: new Date(Date.now() + 2592000000)
    });

    const promo = retrievePromo(location.query);
    if (promo) {
      cookie.set("_tpxpm", promo, {
        secure: process.env.NODE_ENV === "production",
        sameSite: "Lax",
        expires: new Date(Date.now() + 1209600000)
      });
    }
  }
};

export default handleCookies;
