module.exports = {
  api: {
    host: process.env.APIHOST || "https://tenderplan.dev",
    // host: process.env.APIHOST || "https://tenderplan.ru",
    port: process.env.APIPORT || "443"
  },
  print: {
    host: process.env.PRINTHOST || "https://tenderplan.dev",
    // host: process.env.APIHOST || "https://tenderplan.ru",
    port: process.env.PRINTPORT || "443"
  },
  seo: process.env.SEO || false,
  flagsmith: process.env.FLAGSMITH || "SqEXvL4tFcUKpWQArvTL9p",
  yandex_metrics_id: process.env.YANDEX_METRICS_ID || null,
  google_tag_id: process.env.GOOGLE_TAG_ID || null,
  domain: process.env.HOST || "http://localhost",
  cookieDomain: process.env.COOKIE_DOMAIN || "",
  socket: process.env.SOCKET || "https://tenderplan.dev",
  fileviewer: process.env.FILEVIEWER || null,

  routes: [
    ["/login", "/auth/login"],
    ["/magiclink", "/auth/magiclink"],
    ["/register/user", "/auth/register/user"],
    ["/register/partner", "/auth/register/partner"],
    ["/confirm/invite", "/auth/confirm/invite"],
    ["/logout"],
    ["/logout/all"]
  ],
  custom_ca: true,
  cookies: {
    secret: process.env.COOKIE_SECRET || "05933414-09ed-4d2c-adf4-ae77e9ec0f4a",
    promoName: "_tpxpm"
  }
};
