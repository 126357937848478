import { applyMiddleware, createStore as _createStore } from "redux";
import createMiddleware from "./clientMiddleware";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import logger from "redux-logger";

export default function createStore(history, client, data) {
  const middleware = [
    thunk,
    createMiddleware(client),
    routerMiddleware(history)
  ];

  if (__DEV__ && __CLIENT__ && __DEVTOOLS__) {
    // middleware.push(logger);
  }

  const finalCreateStore = applyMiddleware(...middleware)(_createStore);
  const reducer = require("../client/store/reducers").default();
  const store = finalCreateStore(reducer, data);
  store.asyncReducers = {};

  if (__DEV__ && module.hot) {
    module.hot.accept("../client/store/reducers", () => {
      store.replaceReducer(require("../client/store/reducers").default());
    });
  }

  client.setStore(store);

  return store;
}
