let ParticipantContainer;

const getComponentAsync = async store => {
  const { injectReducer } = await import("../../../store/reducers");

  ParticipantContainer =
    ParticipantContainer || (await import("../../Participant")).default;
  // ParticipantAnalytics =
  //   ParticipantAnalytics ||
  //   ParticipantContainer(
  //     (await import("./containers/ParticipantAnalyticsContainer")).default
  //   );
  injectReducer(store, {
    key: "customers",
    reducer: (await import("@tenderplan3/store/modules/customers")).default
  });
  injectReducer(store, {
    key: "organizations",
    reducer: (await import("@tenderplan3/store/modules/organizations")).default
  });
  injectReducer(store, {
    key: "classificators",
    reducer: (await import("@tenderplan3/store/modules/classificators")).default
  });
  injectReducer(store, {
    key: "participants",
    reducer: (await import("@tenderplan3/store/modules/participants")).default
  });

  return ParticipantContainer;
};

export default store => ({
  path: ":participant",
  getComponent(nextState, cb) {
    getComponentAsync(store).then(res => {
      cb(null, res);
    });
  }
});
