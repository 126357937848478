"use strict";

import createAnalytics from "./Analytics";
import createSearch from "./Search";
import createCustomerSearch from "./CustomerSearch";
import createParticipantSearch from "./ParticipantSearch";
import createOrganizationSearch from "./OrganizationSearch";
// import createProduct from "./Product";
import createTenderAnalytics from "./TenderAnalytics";
import createRelationAnalytics from "./RelationAnalytics";
import createRnpSearch from "./RnpSearch";
import createBankGuaranteesSearch from "./BankGuaranteesSearch";
import createSearchByNumber from "./SearchByNumber";

let Component;

export default store => ({
  path: "analytics",
  getComponent(nextState, cb) {
    Promise.all([
      import("../../containers/PaymentStateContainer"),
      import("../../layouts/AnalyticsLayout")
    ]).then(result => {
      const [wrapPayment, component] = result;

      Component = Component || wrapPayment.default(component.default);

      cb(null, Component);
    });
  },
  indexRoute: createAnalytics(store),
  childRoutes: [
    createSearch(store),
    createCustomerSearch(store),
    createParticipantSearch(store),
    createOrganizationSearch(store),
    // createProduct(store),
    createTenderAnalytics(store),
    createRelationAnalytics(store),
    createRnpSearch(store),
    createBankGuaranteesSearch(store),
    createSearchByNumber(store)
  ],
  onEnter(nextState, replace, callback) {
    const state = store.getState();
    if (!state.globals.paid && !state.globals.admin) {
      replace("/app/settings?section=company");
    }
    callback();
  }
});
