let MyCompany;

const getComponentAsync = async store => {
  const { injectReducer } = await import("../../../../store/reducers");

  MyCompany =
    MyCompany || (await import("./containers/MyCompanyContainer")).default;

  injectReducer(store, {
    key: "mycompany",
    reducer: (await import("@tenderplan3/store/modules/mycompany")).default
  });

  injectReducer(store, {
    key: "organizations",
    reducer: (await import("@tenderplan3/store/modules/organizations")).default
  });

  injectReducer(store, {
    key: "participants",
    reducer: (await import("@tenderplan3/store/modules/participants")).default
  });

  injectReducer(store, {
    key: "customers",
    reducer: (await import("@tenderplan3/store/modules/customers")).default
  });

  return MyCompany;
};

export default store => ({
  getComponent(nextState, cb) {
    getComponentAsync(store).then(component => {
      cb(null, component);
    });
  }
});
