import createMyCompany from "./MyCompany";

let MyCompanyMainContainer;

const getComponentAsync = async () => {
  MyCompanyMainContainer =
    MyCompanyMainContainer ||
    (await import("./containers/MyCompanyMainContainer")).default;

  return MyCompanyMainContainer;
};

export default store => ({
  getComponent(nextState, cb) {
    getComponentAsync().then(component => {
      cb(null, component);
    });
  },
  indexRoute: createMyCompany(store)
  // childRoutes: [createMyCompany(store)]
  // add my rivals and my customers later
  // childRoutes: [createMyCompany(store)]
});
