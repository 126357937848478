// функция выбора роута правой панели
const findRightRoute = async query => {
  if (query && query.customer) {
    return await import("../Customer");
  }
  if (query && query.participant) {
    return await import("../Participant");
  }
  if (query && query.organization) {
    return await import("../Organization");
  }
  if (query && query.mark && query.analyze) {
    return await import("../AnalyticsMarks");
  }
  if (query && query.guarantee) {
    return await import("../BankGuarantees");
  }
  if (query && query.rnp) {
    return await import("../Rnp");
  }

  return await import("../Tender");
};

const getTypeRoute = query => {
  if (query && query.tender) {
    return "tender";
  }
  return "other";
};

// асинхронная функция подрузки роутов для средней и правой панелей
const preload = async query => {
  const list = (await import("../Tenders")).default;
  const item = (await findRightRoute(query)).default;
  const routeType = getTypeRoute(query);

  return { list, item, routeType };
};

export default store => ({
  getComponents(nextState, cb) {
    const { query } = nextState.location;

    preload(query).then(routesObj => {
      Promise.all([
        import("../../store/reducers"),
        import("@tenderplan3/store/modules/comments"),
        import("@tenderplan3/store/modules/classificators"),
        import("@tenderplan3/store/modules/customers"),
        import("@tenderplan3/store/modules/organizations"),
        import("@tenderplan3/store/modules/participants"),
        import("@tenderplan3/store/modules/products"),
        import("@tenderplan3/store/modules/marksAnalytics"),
        import("@tenderplan3/store/modules/guarantees"),
        import("@tenderplan3/store/modules/rnp"),
        import("@tenderplan3/store/modules/relations"),
        import("@tenderplan3/store/modules/filters")
      ]).then(results => {
        const [
          reducers,
          comments,
          classificators,
          customers,
          organizations,
          participants,
          products,
          marksAnalytics,
          guarantees,
          rnp,
          relations,
          filters
        ] = results;
        const { injectReducer } = reducers;

        injectReducer(store, { key: "comments", reducer: comments.default });
        injectReducer(store, {
          key: "classificators",
          reducer: classificators.default
        });
        injectReducer(store, { key: "customers", reducer: customers.default });
        injectReducer(store, {
          key: "organizations",
          reducer: organizations.default
        });
        injectReducer(store, {
          key: "participants",
          reducer: participants.default
        });
        injectReducer(store, { key: "products", reducer: products.default });
        injectReducer(store, {
          key: "marksAnalytics",
          reducer: marksAnalytics.default
        });
        injectReducer(store, {
          key: "guarantees",
          reducer: guarantees.default
        });
        injectReducer(store, { key: "rnp", reducer: rnp.default });
        injectReducer(store, { key: "relations", reducer: relations.default });
        injectReducer(store, { key: "filters", reducer: filters.default });

        cb(null, routesObj);
      });
    });
  }
});
