import createBankGuaranteeAnalytics from "../BankGuaranteesAnalytics";

let BankGuaranteesSearchContainer;

const getIndexRouteAsync = async store => {
  const { injectReducer } = await import("../../../store/reducers");

  BankGuaranteesSearchContainer =
    BankGuaranteesSearchContainer ||
    (await import("./containers/BankGuaranteesSearchContainer")).default;
  injectReducer(store, {
    key: "guarantees",
    reducer: (await import("@tenderplan3/store/modules/guarantees")).default
  });

  return BankGuaranteesSearchContainer;
};

export default store => ({
  path: "guarantee",
  getIndexRoute(location, cb) {
    // todo check why async
    getIndexRouteAsync(store).then(res => {
      cb(null, res);
    });
  },
  getComponent(location, cb) {
    import("../../../layouts/BankGuaranteesSearchLayout").then(component => {
      cb(null, component.default);
    });
  },
  childRoutes: [createBankGuaranteeAnalytics(store)]
});
