import getParticipantAnalytics from "../ParticipantAnalytics";

let ParticipantSearch;

const getIndexRouteAsync = async store => {
  const { injectReducer } = await import("../../../store/reducers");

  ParticipantSearch =
    ParticipantSearch ||
    (await import("./containers/ParticipantSearchContainer")).default;
  injectReducer(store, {
    key: "organizations",
    reducer: (await import("@tenderplan3/store/modules/organizations")).default
  });

  return ParticipantSearch;
};

export default store => ({
  path: "participant",
  getIndexRoute(location, cb) {
    getIndexRouteAsync(store).then(res => {
      cb(null, res);
    });
  },
  getComponent(nextState, cb) {
    import("../../../layouts/ParticipantSearchLayout").then(component => {
      cb(null, component.default);
    });
  },
  childRoutes: [getParticipantAnalytics(store)]
});
