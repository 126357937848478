let Relation;

const getComponentAsync = async store => {
  const { injectReducer } = await import("../../../store/reducers");

  Relation =
    Relation || (await import("./containers/RelationContainer")).default;
  injectReducer(store, {
    key: "customers",
    reducer: (await import("@tenderplan3/store/modules/customers")).default
  });
  injectReducer(store, {
    key: "participants",
    reducer: (await import("@tenderplan3/store/modules/participants")).default
  });
  injectReducer(store, {
    key: "classificators",
    reducer: (await import("@tenderplan3/store/modules/classificators")).default
  });
  injectReducer(store, {
    key: "organizations",
    reducer: (await import("@tenderplan3/store/modules/organizations")).default
  });
  injectReducer(store, {
    key: "products",
    reducer: (await import("@tenderplan3/store/modules/products")).default
  });
  injectReducer(store, {
    key: "relations",
    reducer: (await import("@tenderplan3/store/modules/relations")).default
  });

  return Relation;
};

export default store => ({
  path: "relations",
  getComponent(nextState, cb) {
    getComponentAsync(store).then(component => {
      cb(null, component);
    });
  }
});
