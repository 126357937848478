import createWorkspace from "../Workspace";

let Component;

export default store => ({
  indexRoute: createWorkspace(store),
  getComponent(nextState, cb) {
    Promise.all([
      import("../../containers/PaymentStateContainer"),
      import("./containers/HomeContainer")
    ]).then(result => {
      const [wrapPayment, component] = result;

      Component = Component || wrapPayment.default(component.default);

      cb(null, Component);
    });
  },
  onEnter(nextState, replace, callback) {
    const state = store.getState();
    if (!state.globals.paid && !state.globals.admin) {
      replace("/app/settings?section=company");
    }
    callback();
  }
});
