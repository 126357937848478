let TutorialContainer;

const getComponentAsync = async store => {
  const { injectReducer } = await import("../../store/reducers");

  TutorialContainer =
    TutorialContainer ||
    (await import("./containers/TutorialContainer")).default;

  injectReducer(store, {
    key: "info",
    reducer: (await import("@tenderplan3/store/modules/info")).default
  });

  injectReducer(store, {
    key: "keys",
    reducer: (await import("@tenderplan3/store/modules/keys")).default
  });

  injectReducer(store, {
    key: "marks",
    reducer: (await import("@tenderplan3/store/modules/marks")).default
  });

  injectReducer(store, {
    key: "tools",
    reducer: (await import("@tenderplan3/store/modules/tools")).default
  });

  return TutorialContainer;
};

export default store => ({
  path: "tutorial",
  getComponent(location, cb) {
    getComponentAsync(store).then(component => {
      cb(null, component);
    });
  }
});
