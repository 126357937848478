import createReducer from "@tenderplan3/store/createReducer";
import * as constants from "./constants";

// -------------------------------------
// Reducer
// -------------------------------------

const initialState = {
  initialized: false,
  impersonate: null,
  paid: true
};

const ACTION_HANDLERS = {
  [constants.INITIALIZE_APP]: state => ({
    ...state,
    initialized: true
  }),
  [constants.UPDATE_GLOBALS]: (state, { params }) => ({
    ...state,
    ...params
  })
};

export default createReducer(initialState, ACTION_HANDLERS);
