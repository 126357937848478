import { combineReducers } from "redux";
import { routerReducer as routing } from "react-router-redux";
import { reducer as reduxAsyncConnect } from "redux-connect";
import mapValues from "lodash/mapValues";

import globals from "./modules/globals";
import auth from "@tenderplan3/store/modules/auth";
import info from "@tenderplan3/store/modules/info";
import {
  LOGOUT_SUCCESS,
  LOGOUT_ALL_SUCCESS,
  SOCKET_LOGOUT_ALL
} from "@tenderplan3/store/modules/auth/constants";

const LOGOUT_EVENTS = new Set([
  LOGOUT_ALL_SUCCESS,
  LOGOUT_SUCCESS,
  SOCKET_LOGOUT_ALL
]);
const ROUTING_KEYS = new Set(["reduxAsyncConnect", "routing", "globals"]);

export const makeRootReducer = asyncReducers => {
  const combinedReducers = combineReducers({
    // Add sync reducers here
    reduxAsyncConnect,
    routing,
    auth,
    info,
    globals,
    ...asyncReducers
  });

  return (state, action) => {
    if (LOGOUT_EVENTS.has(action.type)) {
      state = mapValues(state, (v, k) => {
        if (ROUTING_KEYS.has(k)) {
          return v;
        }
        return undefined;
      });
    }

    return combinedReducers(state, action);
  };
};

export const injectReducer = (store, { key, reducer }) => {
  if (!store.asyncReducers[key]) {
    store.asyncReducers[key] = reducer;
    store.replaceReducer(makeRootReducer(store.asyncReducers));
  }
};

export default makeRootReducer;
