let NotFound;

const getComponentAsync = async store => {
  NotFound = NotFound || (await import("./components/NotFound")).default;

  return NotFound;
};

export default store => ({
  path: "*",
  status: 404,
  getComponent(location, cb) {
    getComponentAsync(store).then(component => {
      cb(null, component);
    });
  }
});
