let Bill;

export default store => ({
  path: "/app/bill/:token",
  getComponent(nextState, cb) {
    /** глобально inject части store для левой панели
     * и другие части store, необходимые для
     * повсеместного использования вроде history
     * необходимость - повсеместный доступ через сокеты
     * к определенным частям store
     */
    Promise.all([
      import("../../store/reducers"),
      import("./containers/BillContainer"),
      import("../../store/modules/payers"),
      import("../../store/modules/orders")
    ]).then(results => {
      const [reducers, bill, payers, orders] = results;

      const { injectReducer } = reducers;

      // каждый раз когда меняется урл срабатывает этот метод,
      // в результате в App ссылка меняется, хотя компонент остается тот же
      // тут костыль, который не будет менять ссылку
      Bill = Bill || bill.default;

      injectReducer(store, { key: "payers", reducer: payers.default });
      injectReducer(store, { key: "orders", reducer: orders.default });

      /*  Return getComponent   */
      cb(null, Bill);
    });
  }
});
