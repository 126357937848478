// import createMyCompany from "../MyCompanyMain";

let MyCompanyEnter;

const getComponentAsync = async store => {
  // const { injectReducer } = await import("../../../store/reducers");

  MyCompanyEnter =
    MyCompanyEnter ||
    (await import("./containers/MyCompanyEnterContainer")).default;

  //   injectReducer(store, {
  //     key: "rnp",
  //     reducer: (await import("@tenderplan3/store/modules/rnp")).default
  //   });

  return MyCompanyEnter;
};
// const getIndexRouteAsync = async store => {
//   // const { injectReducer } = await import("../../../store/reducers");

//   MyCompanyEnter =
//     MyCompanyEnter ||
//     (await import("./containers/MyCompanyEnterContainer")).default;

//   return MyCompanyEnter;
// };

export default store => ({
  path: "enter",
  // getIndexRoute(location, cb) {
  //   getIndexRouteAsync(store).then(component => {
  //     cb(null, component);
  //   });
  // },
  getComponent(nextState, cb) {
    getComponentAsync(store).then(component => {
      cb(null, component);
    });
  }
  // childRoutes: [createMyCompany(store)]
});
