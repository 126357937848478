import { push } from "react-router-redux";
import hash from "object-hash";
import get from "lodash/get";
import flagsmith from "flagsmith";

import { logout } from "@tenderplan3/store/modules/auth";

import {
  getRequestIsLocked,
  lockRequest,
  unlockRequest
} from "@tenderplan3/store/modules/lockedRequests";

import { getSocketId } from "@tenderplan3/store/modules/info";

import { addPopupNotification } from "../client/store/modules/popups/notifications/actions";
import { updateGlobals } from "../client/store/modules/globals/index";

export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => next => action => {
    if (!action) return next({ type: "__CACHED" });

    if (typeof action === "function") {
      return action(dispatch, getState);
    }

    const { promise, lockingQuery, types, ...rest } = action; // eslint-disable-line no-redeclare

    if (!promise) {
      return next(action);
    }

    const state = getState();
    const lockingHash = lockingQuery
      ? hash({
          types,
          lockingQuery
        })
      : null;

    if (state && state.info && getSocketId) {
      client.socketId = getSocketId(state.info);
    }

    if (state && lockingHash) {
      if (getRequestIsLocked(state.lockedRequests, lockingHash)) {
        return null;
      }

      dispatch(lockRequest(lockingHash));
    }

    const [REQUEST, SUCCESS, FAILURE] = types;
    next({ ...rest, type: REQUEST });

    const actionPromise = promise(client, dispatch);
    if (actionPromise) {
      actionPromise
        .then(
          result => {
            if (lockingHash) {
              dispatch(unlockRequest(lockingHash));
            }
            return next({ ...rest, result, type: SUCCESS });
          },
          error => {
            if (lockingHash) {
              dispatch(unlockRequest(lockingHash));
            }
            // logout if unauthorized
            if (error.status === 401 && action.logout !== false) {
              const { routing } = getState();
              const { pathname, search } = routing.locationBeforeTransitions;
              const path =
                pathname.indexOf("/app") === 0 ? pathname + search : undefined;
              const preventRedirect = pathname.indexOf("/app") !== 0;
              dispatch(logout(path, preventRedirect));
            }

            // redirect if payment overdue
            if (error.status === 402) {
              const { routing } = getState();
              const { pathname, search } = routing.locationBeforeTransitions;

              if (flagsmith.hasFeature("auto_payments")) {
                dispatch(updateGlobals({ paid: false }));

                // const ALLOWED_ROUTES = [
                //   "/app/settings?section=company",
                //   "/app/settings?section=help"
                // ];

                // const location = pathname + search;
                // if (!ALLOWED_ROUTES.some(v => location.startsWith(v))) {
                //   dispatch(push("/app/settings?section=company"));
                // }
              } else {
                if (pathname.indexOf("/app") === 0) {
                  const path = pathname + search;
                  window.location = `/expired?next=${encodeURIComponent(path)}`;
                }
              }
            }

            // show notification that something went wrong
            if (
              error.status === 500 &&
              get(error, "response.req.method") === "POST"
            ) {
              dispatch(
                addPopupNotification(
                  {
                    id: "error",
                    title: "Ой! Произошла ошибка.",
                    message: "Попробуйте обновить страницу"
                  },
                  { desktop: false }
                )
              );
            }

            return next({ ...rest, error, type: FAILURE });
          }
        )
        .catch(error => {
          next({ ...rest, error, type: FAILURE });
        });
    }

    return actionPromise;
  };
}
