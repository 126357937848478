import { isMobile, isDesktopApp } from "./device";

const notify = (title, options) => {
  const notification = new Notification(title, {
    renotify: true,
    icon: "/public/app/img/desktop-notification-icon.png",
    ...options
  });

  const onclick = options.onclick || (() => {});
  notification.onclick = () => {
    window.focus();
    onclick();
    notification.close();
  };
};

export const requestPermission = cb => {
  // Let's check if the browser supports notifications and it's not mobile
  if (__SERVER__ || !("Notification" in window) || isMobile()) {
    return;
  }
  Notification.requestPermission(cb);
};

export const createDesktopNotification = (title, options) => {
  // Let's check if the browser supports notifications and it's not mobile
  if (__SERVER__ || !("Notification" in window) || isMobile()) {
    return;
  }
  if (isDesktopApp()) {
    if (!Notification.isSupported()) {
      return;
    }

    // Don't send push notifications if the app is focused, duh
    if (window.interop.isFocused()) {
      return;
    }

    notify(title, options);
  } else {
    // If the user accepts, let's create a notification
    Notification.requestPermission(permission => {
      if (permission === "granted") {
        notify(title, options);
      }
    });
  }
};

export const notEnoughRights = {
  id: "",
  title: `У вас недостаточно прав`,
  message: "У вас недостаточно прав, чтобы сделать изменения",
  redBg: true,
  options: {}
};
