import { POPUP_TIMER_ADD, POPUP_TIMER_REMOVE } from "./constants";

export const addPopupTimer = (id, timer) => (dispatch, getState) => {
  // Clear previous timer if exists
  const state = getState();
  if (state[id]) clearTimeout(state[id]);

  dispatch({
    type: POPUP_TIMER_ADD,
    params: { id, timer }
  });
};

export const removePopupTimer = id => (dispatch, getState) => {
  // Clear timer if exists
  const state = getState();
  if (state[id]) clearTimeout(state[id]);

  dispatch({
    type: POPUP_TIMER_REMOVE,
    params: { id }
  });
};
