import createRnpAnalytics from "../RnpAnalytics";

let RnpSearchContainer;

const getIndexRouteAsync = async store => {
  const { injectReducer } = await import("../../../store/reducers");

  RnpSearchContainer =
    RnpSearchContainer ||
    (await import("./containers/RnpSearchContainer")).default;
  injectReducer(store, {
    key: "rnp",
    reducer: (await import("@tenderplan3/store/modules/rnp")).default
  });

  return RnpSearchContainer;
};

export default store => ({
  path: "rnp",
  getIndexRoute(location, cb) {
    getIndexRouteAsync(store).then(component => {
      cb(null, component);
    });
  },
  getComponent(nextState, cb) {
    import("../../../layouts/RnpSearchLayout").then(component => {
      cb(null, component.default);
    });
  },
  childRoutes: [createRnpAnalytics(store)]
});
