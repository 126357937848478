let SearchByNumberAnalytics;

const getComponentAsync = async store => {
  SearchByNumberAnalytics =
    SearchByNumberAnalytics ||
    (await import("./containers/SearchByNumberContainer")).default;
  return SearchByNumberAnalytics;
};

const createSearchByNumber = store => ({
  path: ":number",
  getComponent(nextState, cb) {
    getComponentAsync(store).then(component => {
      cb(null, component);
    });
  }
});

export default store => ({
  path: "number",
  childRoutes: [createSearchByNumber(store)]
});
