import React, { Component } from "react";
import PropTypes from "prop-types";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { ReduxAsyncConnect } from "redux-connect";
import { Router } from "react-router";
// import routes from './routes/index.js';
import ErrorBoundary from "../helpers/ErrorBoundary";

import "react-virtualized/styles.css";
import "react-select/dist/react-select.css";

export default class extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    // renderProps: PropTypes.object,
    routes: PropTypes.object
  };

  render() {
    const { store, history, client, routes } = this.props;

    return (
      <HelmetProvider>
        <Provider store={store}>
          <ErrorBoundary>
            <Router
              key={module.hot && new Date()}
              children={routes}
              render={props => (
                <ReduxAsyncConnect
                  {...props}
                  helpers={{ client }}
                  filter={item => !item.deferred}
                />
              )}
              history={history}
            />
          </ErrorBoundary>
        </Provider>
      </HelmetProvider>
    );
  }
}
