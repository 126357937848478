import superagent from "superagent";

// import isNil from 'lodash/isNil';
import config from "config";

// const urls = config.routes.map(arr => arr[0]);

const segments = ["/cli", "/auth", "/api"];
const methods = ["get", "post", "put", "patch", "del"];

const localStorageSupported = typeof localStorage !== "undefined";

function formatUrl(path) {
  const adjustedPath = path[0] !== "/" ? "/" + path : path;
  const isSegmentSpecified = segments.some(v => adjustedPath.startsWith(v));

  // Prepend `/api` to relative URL, to proxy to API server.
  const pathWithSegment = isSegmentSpecified
    ? adjustedPath
    : `/api${adjustedPath}`;

  if (__SERVER__) {
    // Prepend host and port of the API server to the path.
    return config.api.host + ":" + config.api.port + pathWithSegment;
  }

  return pathWithSegment;
}

export default class ApiClient {
  constructor(req) {
    this.cookies = [];
    this.socketId = null;

    methods.forEach(method => {
      this[method] = (
        path,
        { params, data, headers } = {},
        anonymous = false
      ) =>
        new Promise((resolve, reject) => {
          const formattedUrl = formatUrl(path);
          const request = superagent[method](formattedUrl);
          if (params) {
            request.query(params);
          }

          if (__SERVER__) {
            if (req.query.firm) {
              const impersonate = `${req.query.firm}&${
                req.query.mode === "edit" ? "edit" : "view"
              }`;
              request.set("Impersonate", impersonate);
            } else if (req.headers.impersonate) {
              request.set("Impersonate", req.headers.impersonate);
            }
          } else {
            const impersonate =
              localStorageSupported && sessionStorage.getItem("impersonate");
            if (impersonate) {
              request.set("Impersonate", impersonate);
            }
          }

          const jwt = __SERVER__
            ? req.signedCookies && req.signedCookies.jwt
            : this.getToken() ||
              (localStorageSupported && localStorage.getItem("token"));

          const sid = this.socketId;

          if (!anonymous && jwt) {
            request.set("Authorization", jwt);
          }

          if (sid) {
            request.set("Socket", sid);
          }

          if (data) {
            request.send(data);
          }

          request.end((err, res = {}) => {
            err ? reject(res.body || err) : resolve(res.body);
          });
        });
    });
  }

  setStore(store) {
    this.__store = store;
  }

  getToken() {
    if (!this.__store) return null;
    return this.__store.getState().auth.token;
  }

  /*
   * There's a V8 bug where, when using Babel, exporting classes with only
   * constructors sometimes fails. Until it's patched, this is a solution to
   * "ApiClient is not defined" from issue #14.
   * https://github.com/erikras/react-redux-universal-hot-example/issues/14
   *
   * Relevant Babel bug (but they claim it's V8): https://phabricator.babeljs.io/T2455
   *
   * Remove it at your own risk.
   */
  empty() {}
}
