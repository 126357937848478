/*  Note: Instead of using JSX, we recommend using react-router
    PlainRoute objects to build route definitions.   */

/*  Note: for complex applications we should split our routes to chunks
    (Code splitting with react-router v3 and webpack v2)
    following this guide:
    https://brotzky.co/blog/code-splitting-react-router-webpack-2/
*/
import CoreLayout from "../containers/CoreContainer";
import createNotFound from "./NotFound";
import createApp from "./App";
// KEEP: bill chunks are required for print/pdf
import createBill from "./Bill";
import createBillOld from "../legacy/payments/routes/Bill";

export default store => ({
  path: "/",
  component: CoreLayout,
  childRoutes: [
    // createExpired(store),
    // createLogin(store),
    // createRecover(store),
    // createUnsubscribe(store),
    // createRegistration(store),
    // createPricesPage(store),
    // createPricesPage2022(store),
    createApp(store),
    createBill(store),
    createBillOld(store),
    // createBill(store),
    // createAuthorize(store),
    // createIntegrationPage(store),
    // createTenderModelTest(store),
    // createExternal(store),
    // createExternal2(store),
    // createAgreement(store),
    // createBill(store),
    // createUserAgreement(store),
    // createNewMap(store),
    // createPartners(store),
    // createBill(store),
    // createRedirectReferal(store),
    // createEmailConfirm(store),
    // createPlatforms(store),
    // createSearch(store),
    // createControl(store),
    // createCovid19(store),
    // createAccompanyLanding(store),
    // createTinkoffLanding(store),
    // createAccompanyAggreement(store),
    // createGeoLanding(store),
    // // createNewYearEasy(store),
    // // createNewYearHard(store),
    // // createValentinesDay(store),

    // createTestTinkoffPaymentPage(), // TEST !!!!

    // createVacancies(store),
    // createAnalytics(store),
    // createPartnershipOffers(store),
    // createHelp(store),
    // createComparePage(store),
    // createDownloadPage(store),
    createNotFound(store)
  ]
});
