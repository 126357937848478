const path = require("path");

// ========================================================
// Default Configuration
// ========================================================
const config = {
  env: process.env.NODE_ENV || "development",
  api: {
    host: "http://localhost",
    port: 3001
  },
  print: {
    host: "http://localhost",
    port: 3002
  },
  domain: "http://localhost",
  disable_ssr: true, // disabling server-side rendering
  // ----------------------------------
  // Server Configuration
  // ----------------------------------
  server: {
    http: {
      host: "127.0.0.1",
      port: process.env.PORT || 3000
    }
  },
  paths: {
    root: path.resolve(__dirname, ".."),
    src: {
      root: path.resolve(__dirname, "..", "src/"),
      client: path.resolve(__dirname, "../..", "src/", "client/"),
      server: path.resolve(__dirname, "..", "src/", "server/")
    },
    dist: {
      root: path.resolve(__dirname, "..", "dist/"),
      client: path.resolve(__dirname, "..", "dist/", "client/"),
      server: path.resolve(__dirname, "..", "dist/", "server/")
    },
    dir_test: path.resolve(__dirname, "..", "tests/"),
    // TODO pochenite
    ca: path.resolve(__dirname, "../..", "src/server/ssl/office.crt")
  },
  development: {
    webpack: {
      development_server: {
        host: "127.0.0.1",
        port: 3001
      }
    }
  },
  // ----------------------------------
  // Test Configuration
  // ----------------------------------
  coverage_reporters: [
    { type: "text-summary" },
    { type: "lcov", dir: "coverage" }
  ]
};

module.exports = config;
