import get from "lodash/get";
import { POPUP_NOTIFICATION_ADD, POPUP_NOTIFICATION_REMOVE } from "./constants";

import { createDesktopNotification } from "../../../../helpers/notifications";
import { generateUniqueId } from "../../../../helpers/tools";

import { addPopupTimer, removePopupTimer } from "../timers/actions";

export const removePopupNotification = id => ({
  type: POPUP_NOTIFICATION_REMOVE,
  params: { id }
});

export const addPopupNotification = (popup, options) => (
  dispatch,
  getState
) => {
  const { toast, desktop, expire } = {
    toast: true,
    desktop: true,
    expire: 6000,
    ...options
  };
  const id = popup.id || generateUniqueId();

  if (toast && !document.hidden) {
    // autoremove
    if (expire) {
      const timer = setTimeout(() => {
        dispatch(removePopupTimer(id));
        dispatch(removePopupNotification(id));
      }, expire);

      dispatch(addPopupTimer(id, timer));
    }

    // create toast
    dispatch({
      type: POPUP_NOTIFICATION_ADD,
      params: { popup: { ...popup, id } }
    });
  }

  // show desktop notification
  if (desktop && document.hidden) {
    const state = getState();
    const users = state.info.users;
    const showDesktopNotifications = get(
      users.byId,
      [users.currentId, "settings", "desktopNotifications"],
      false
    );
    if (showDesktopNotifications) {
      createDesktopNotification(popup.title, {
        dir: "auto",
        lang: "ru",
        body: popup.body,
        tag: id,
        icon: popup.icon,
        silent: popup.silent,
        requireInteraction: popup.requireInteraction,
        onclick: popup.onclick
      });
    }
  }
};
