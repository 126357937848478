import "core-js";
import "core-js/modules/es6.symbol";

import React from "react";
import ReactDOM from "react-dom";
import createBrowserHistory from "history/lib/createBrowserHistory";
import { useRouterHistory, match } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";
// import 'chartjs-plugin-datalabels';
// import Redbox from 'redbox-react';
import { AppContainer } from "react-hot-loader";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";

// import AppContainer from './containers/AppContainer';
import config from "config";
// import ErrorBoundary from '../helpers/ErrorBoundary';
import createStore from "../helpers/createStore";
import ApiClient from "../helpers/ApiClient";
import Root from "./root";
// import getRoutes from './routes/index.js';

import "./styles/core.scss";
import "./styles/buttons.scss";
import "./styles/tooltipStyle.scss";

import * as Sentry from "@sentry/browser";

const client = new ApiClient();

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://be79bc9cdcf64b0b96ebb2e14e2fe6d1@sentry.tenderplan.ru/5"
  });
}

// ========================================================
// Browser History Setup
// ========================================================
const browserHistory = useRouterHistory(createBrowserHistory)({
  basename: ""
});

// ========================================================
// Store and History Instantiation
// ========================================================
// Create redux store and sync with react-router-redux. We have installed the
// react-router-redux reducer under the routerKey "router" in src/routes/index.js,
// so we need to provide a custom `selectLocationState` to inform
// react-router-redux of its location.

// state grabbed from a global variable injected into the server-generated HTML
const initialState = window.__data;

// Allow the passed state to be garbage-collected
delete window.__data;

// Create Redux store with initial state
const store = createStore(browserHistory, client, initialState);
const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: state => state.routing
});
// const routes = getRoutes(store);

// ========================================================
// Developer Tools Setup
// ========================================================
if (__DEV__) {
  if (window.devToolsExtension) {
    window.devToolsExtension.open();
  }
}

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById("root");

let render = (routerKey = 0) => {
  const routes = require("./routes/index").default(store);

  match({ history, routes }, (error, redirectLocation, renderProps) => {
    ReactDOM.hydrate(
      <FlagsmithProvider
        options={{
          enableAnalytics: true,
          preventFetch: true,
          api: "https://flags.tenderplan.ru/api/v1/",
          environmentID: config.flagsmith
        }}
        flagsmith={flagsmith}
      >
        <AppContainer>
          <Root
            store={store}
            history={history}
            client={client}
            routes={routes || {}}
          />
        </AppContainer>
      </FlagsmithProvider>,
      MOUNT_NODE
    );
  });
};
// ReactDOM.hydrate(component, MOUNT_NODE);

if (__DEV__) {
  window.React = React; // enable debugger

  if (
    !config.disable_ssr &&
    (!MOUNT_NODE ||
      !MOUNT_NODE.firstChild ||
      !MOUNT_NODE.firstChild.attributes ||
      !MOUNT_NODE.firstChild.attributes["data-reactroot"])
  ) {
    console.error("Server-side React render was discarded.");
  }
}

if (__DEV__ && module.hot) {
  const renderApp = render;
  const renderError = error => {
    const RedBox = require("redbox-react");
    ReactDOM.render(<RedBox error={error} />, MOUNT_NODE);
  };
  render = () => {
    try {
      renderApp(Math.random());
    } catch (error) {
      renderError(error);
    }
  };
  module.hot.accept(["./routes/index"], () => render());
}

render(0);
