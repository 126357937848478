let Search;

const getComponentAsync = async store => {
  const { injectReducer } = await import("../../../store/reducers");

  // keysContainer =
  //   keysContainer ||
  //   (await import("../../../containers/KeysContainer")).default;
  Search = Search || (await import("./containers/SearchContainer")).default;

  injectReducer(store, {
    key: "customers",
    reducer: (await import("@tenderplan3/store/modules/customers")).default
  });
  injectReducer(store, {
    key: "participants",
    reducer: (await import("@tenderplan3/store/modules/participants")).default
  });
  injectReducer(store, {
    key: "classificators",
    reducer: (await import("@tenderplan3/store/modules/classificators")).default
  });
  injectReducer(store, {
    key: "keys",
    reducer: (await import("@tenderplan3/store/modules/keys")).default
  });
  injectReducer(store, {
    key: "search",
    reducer: (await import("@tenderplan3/store/modules/search")).default
  });
  injectReducer(store, {
    key: "products",
    reducer: (await import("@tenderplan3/store/modules/products")).default
  });
  injectReducer(store, {
    key: "tools",
    reducer: (await import("@tenderplan3/store/modules/tools")).default
  });

  return Search;
};

export default store => ({
  path: "search",
  getComponent(nextState, cb) {
    getComponentAsync(store).then(res => {
      cb(null, res);
    });
  }
});
