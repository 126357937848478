import * as constants from "./constants";
import reducer from "./reducer";

// ------------------------------------
// Actions
// ------------------------------------

export const initialize = () => ({
  type: constants.INITIALIZE_APP
});

export const updateGlobals = params => ({
  type: constants.UPDATE_GLOBALS,
  params
});

// ------------------------------------
// Reducers
// ------------------------------------

export default reducer;
