"use strict";
import { twoDigits } from "./tools.js";

const isWithTimezone = text => {
  return (
    text.indexOf("+") !== -1 ||
    text.indexOf("Z") !== -1 ||
    text.indexOf("z") !== -1
  );
};

const getWithTimezone = (text, timezone) => {
  let TwoDigits = twoDigits(timezone);
  if (TwoDigits[0] !== "-") {
    TwoDigits = "+" + TwoDigits;
  }
  const withTimezone = text + TwoDigits + ":00";
  return new Date(withTimezone);
};

export const getTimezoneString = timezone => {
  return timezone === 0 ? "" : timezone > 0 ? "+" + timezone : timezone;
};

export const getMSK = timezone => {
  return " (МСК" + getTimezoneString(timezone - 3) + ")";
};

export const toTimeZone = (date, timeZone) => {
  return date + timeZone + new Date().getTimezoneOffset() * 60000;
};

// перевод в московское время
const toSpecificTimezone = (date, timezone) => {
  const timezoneAdd = timezone * 3600000;
  return new Date(
    date.getTime() + date.getTimezoneOffset() * 60000 + timezoneAdd
  );
};

export default (dateTimeText, useLocalTime, timezone) => {
  if (dateTimeText === undefined || dateTimeText === "") {
    return null;
  }

  let date;
  // if we dont have timezone in date - add it
  if (isWithTimezone(dateTimeText) === false && timezone) {
    date = getWithTimezone(dateTimeText, timezone);
  } else {
    date = new Date(dateTimeText);
  }
  // if valid date
  if (isNaN(date.getTime())) {
    return null;
  }

  let ifMSK = "";
  // to specific timezone
  if (useLocalTime === false) {
    if (!timezone) {
      timezone = 3;
    }

    date = toSpecificTimezone(date, timezone);
    ifMSK = getMSK(timezone);
  }

  const day = date.getDate().toString();
  const month = (date.getMonth() + 1).toString();
  const year = date.getFullYear().toString();
  const hour = date.getHours().toString();
  const minute = date.getMinutes().toString();

  return (
    twoDigits(day) +
    "." +
    twoDigits(month) +
    "." +
    year +
    " в " +
    twoDigits(hour) +
    ":" +
    twoDigits(minute) +
    ifMSK
  );
};

export const getBrowserTimezone = () => new Date().getTimezoneOffset() * -60000;
