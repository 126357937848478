import isElectron from "is-electron";

export const isMobile = () =>
  navigator &&
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

// A custom method to check whether we're running inside the desktop app or not
// this is necessary because our e2e tests run in Electron (via Cypress), so a simple
// isElectron() check also fires when that's the case
export const isDesktopApp = () => {
  return isElectron() && !window.Cypress && window.interop;
};

export const isInternetExplorer = () =>
  navigator.userAgent.indexOf("MSIE ") > -1 ||
  navigator.userAgent.indexOf("Trident/") > -1;

export const getOSName = () => {
  let OSName = "";

  if (navigator && navigator.appVersion.includes("Win")) OSName = "Win";
  if (navigator && navigator.appVersion.includes("Mac")) OSName = "Mac";
  if (navigator && navigator.appVersion.includes("Linux")) OSName = "Linux";

  return OSName;
};
