import createMyCompanyMain from "./MyCompanyMain";
import createMyCompanyEnter from "./MyCompanyEnter";

let Component;

export default store => ({
  path: "us",
  getComponent(nextState, cb) {
    Promise.all([
      import("../../containers/PaymentStateContainer"),
      import("../../layouts/AnalyticsLayout")
    ]).then(result => {
      const [wrapPayment, component] = result;

      Component = Component || wrapPayment.default(component.default);

      cb(null, Component);
    });
  },
  onEnter(nextState, replace, callback) {
    const state = store.getState();
    if (!state.globals.paid && !state.globals.admin) {
      replace("/app/settings?section=company");
    }
    callback();
  },
  childRoutes: [createMyCompanyMain(store), createMyCompanyEnter(store)]
});
