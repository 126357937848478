let BankGuaranteesAnalytics;

export default store => ({
  path: ":guaranteeId",
  getComponent(nextState, cb) {
    Promise.all([
      import("../../BankGuarantees"),
      import("../../../store/reducers"),
      import("@tenderplan3/store/modules/guarantees")
    ]).then(([component, reducers, guarantees]) => {
      const { injectReducer } = reducers;

      BankGuaranteesAnalytics = BankGuaranteesAnalytics || component.default;
      injectReducer(store, { key: "guarantees", reducer: guarantees.default });

      cb(null, BankGuaranteesAnalytics);
    });
  }
});
