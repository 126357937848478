let createRoute,
  keysmarks,
  users,
  help,
  keysContainer,
  marksContainer,
  company,
  personal;

const loadComponentsAsync = store => {
  return Promise.all([
    import("../../store/reducers"),
    import("../../containers/PaymentStateContainer"),
    import("../../containers/KeysContainer"),
    import("../../containers/MarksContainer"),
    import("./keysmarks/containers/KeysAndMarksContainer"),
    import("@tenderplan3/store/modules/classificators"),
    import("@tenderplan3/store/modules/info"),
    import("@tenderplan3/store/modules/mycompany"),
    import("./users/UsersWrapper"),
    import("./company/CompanyWrapper"),
    import("./personal/PersonalWrapper"),
    import("./help/containers/SettingsHelpContainer"),
    import("../../store/modules/payers"),
    import("../../store/modules/orders")
  ]).then(results => {
    const [
      reducers,
      wrapPayment,
      KeysContainer,
      MarksContainer,
      KeysAndMarksContainer,
      classificators,
      info,
      mycompany,
      UsersWrapper,
      CompanyWrapper,
      PersonalWrapper,
      SettingsHelpContainer,
      payers,
      orders
    ] = results;
    const { injectReducer } = reducers;

    keysContainer = keysContainer || KeysContainer.default;
    marksContainer = marksContainer || MarksContainer.default;
    keysmarks =
      keysmarks ||
      wrapPayment.default(
        keysContainer(marksContainer(KeysAndMarksContainer.default))
      );

    injectReducer(store, {
      key: "classificators",
      reducer: classificators.default
    });
    injectReducer(store, { key: "info", reducer: info.default });
    injectReducer(store, { key: "mycompany", reducer: mycompany.default });
    injectReducer(store, { key: "payers", reducer: payers.default });
    injectReducer(store, { key: "orders", reducer: orders.default });

    users = users || wrapPayment.default(UsersWrapper.default);
    company = company || CompanyWrapper.default;
    personal = personal || wrapPayment.default(PersonalWrapper.default);
    help = help || SettingsHelpContainer.default;

    return {
      keysmarks,
      users,
      personal,
      company,
      help
    };
  });
};

export default store => ({
  getComponents(nextState, cb) {
    loadComponentsAsync(store).then(result => {
      cb(null, result);
    });
  },
  onChange(prevState, nextState, replace, callback) {
    const ALLOWED_ROUTES = [
      "/app/settings?section=company",
      "/app/settings?section=help"
    ];
    const state = store.getState();

    const location = nextState.location.pathname + nextState.location.search;
    if (
      !ALLOWED_ROUTES.some(v => location.startsWith(v)) &&
      !state.globals.paid &&
      !state.globals.admin
    ) {
      replace("/app/settings?section=company");
    }

    callback();
  },
  onEnter(nextState, replace, callback) {
    const ALLOWED_ROUTES = [
      "/app/settings?section=company",
      "/app/settings?section=help"
    ];
    const state = store.getState();

    const location = nextState.location.pathname + nextState.location.search;
    if (
      !ALLOWED_ROUTES.some(v => location.startsWith(v)) &&
      !state.globals.paid &&
      !state.globals.admin
    ) {
      replace("/app/settings?section=company");
    }

    callback();
  }
});
