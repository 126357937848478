let RnpAnalytics;

const getComponentAsync = async store => {
  const { injectReducer } = await import("../../../store/reducers");

  RnpAnalytics = RnpAnalytics || (await import("../../Rnp")).default;
  injectReducer(store, {
    key: "rnp",
    reducer: (await import("@tenderplan3/store/modules/rnp")).default
  });

  return RnpAnalytics;
};

export default store => ({
  path: ":rnp",
  getComponent(nextState, cb) {
    getComponentAsync(store).then(component => {
      cb(null, component);
    });
  }
});
