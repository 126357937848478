"use strict";

import createOrganizationAnalytics from "../OrganizationAnalytics";

let OrganizationSearch;

const getIndexRouteAsync = async store => {
  const { injectReducer } = await import("../../../store/reducers");

  OrganizationSearch =
    OrganizationSearch ||
    (await import("./containers/OrganizationSearchContainer")).default;
  injectReducer(store, {
    key: "organizations",
    reducer: (await import("@tenderplan3/store/modules/organizations")).default
  });

  return OrganizationSearch;
};

export default store => ({
  path: "organization",
  getIndexRoute(location, cb) {
    getIndexRouteAsync(store).then(res => {
      cb(null, res);
    });
  },
  getComponent(nextState, cb) {
    import("../../../layouts/OrganizationSearchLayout").then(component => {
      cb(null, component.default);
    });
  },
  childRoutes: [createOrganizationAnalytics(store)]
});
