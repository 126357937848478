let Bill;

export default store => ({
  path: "/__INTERNAL__/:period",
  getComponent(nextState, cb) {
    /** глобально inject части store для левой панели
     * и другие части store, необходимые для
     * повсеместного использования вроде history
     * необходимость - повсеместный доступ через сокеты
     * к определенным частям store
     */
    Promise.all([
      import("../../../../containers/AuthenticatedContainer"),
      import("../../../../store/reducers"),
      import("./containers/BillContainer"),
      import("@tenderplan3/store/modules/info")
    ]).then(results => {
      const [wrapAuthentication, reducers, bill, info] = results;

      const { injectReducer } = reducers;

      // каждый раз когда меняется урл срабатывает этот метод,
      // в результате в App ссылка меняется, хотя компонент остается тот же
      // тут костыль, который не будет менять ссылку
      Bill = Bill || wrapAuthentication.default(bill.default);

      injectReducer(store, { key: "info", reducer: info.default });

      /*  Return getComponent   */
      cb(null, Bill);
    });
  }
});
