import React from "react";
import Helmet from "react-helmet-async";

import LandingFormPageLayout from "../client/layouts/LandingFormPageLayout";
// import classes from "../client/components/Login/components/LoginView.scss";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    const state = {
      hasError: false,
      error: "no error",
      stack: "no stack"
    };

    this.state = state;
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({
      hasError: true
    });
    // You can also log the error to an error reporting service

    console.warn(
      "error caught by ErrorBoundary: ",
      error,
      "\n componentStack: ",
      info.componentStack
    );
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI

      const textStyle = {
        lineHeight: "27px",
        fontSize: "18px",
        textAlign: "center",

        color: "#4B5E80"
      };
      return (
        <LandingFormPageLayout>
          <Helmet title="Тендерплан: ошибка" />
          <div className="errorPageCenter">
            <div>
              <div className="errorPageTitle">Похоже произошла ошибка</div>
              <div className="errorPageSubTitle">Мы уже работаем над этим</div>
              <div className="errorPageText">
                Попробуйте{" "}
                <span
                  onClick={() => location.reload()}
                  style={{ color: "#1671c7", cursor: "pointer" }}
                >
                  обновить страницу
                </span>{" "}
                страницу или зайти позже. <br /> В любом случае, все ваши данные
                будут бережно сохранены.
              </div>
              <button className="errorPageButton flex-position-center">
                <a href="/">Продолжить работу</a>
              </button>
            </div>
          </div>
        </LandingFormPageLayout>
      );
    }

    return this.props.children;
  }
}
