"use strict";

import createSettingsSections from "./SettingsSections";

export default store => ({
  path: "settings",
  getComponent(nextState, cb) {
    import("../../layouts/SettingsLayout").then(component => {
      cb(null, component.default);
    });
  },
  indexRoute: createSettingsSections(store)
});
