"use strict";

import getCustomerAnalytics from "../CustomerAnalytics";

let CustomerSearch;

const getIndexRouteAsync = async store => {
  const { injectReducer } = await import("../../../store/reducers");

  CustomerSearch =
    CustomerSearch ||
    (await import("./containers/CustomerSearchContainer")).default;
  injectReducer(store, {
    key: "organizations",
    reducer: (await import("@tenderplan3/store/modules/organizations")).default
  });

  return CustomerSearch;
};

export default store => ({
  path: "customer",
  getIndexRoute(location, cb) {
    getIndexRouteAsync(store).then(res => {
      cb(null, res);
    });
  },
  getComponent(nextState, cb) {
    import("../../../layouts/CustomerSearchLayout").then(component => {
      cb(null, component.default);
    });
  },
  childRoutes: [getCustomerAnalytics(store)]
});
