import React from "react";
import { Link } from "react-router";
import Scrollable from "../../components/Scrollable";
import cl from "./LandingFormPageLayout.scss";
import cn from "classnames";
import VkSVG from "../../static/img/landing/social/vk.svg";
import InstagramSVG from "../../static/img/landing/social/inst.svg";
import FacebookSVG from "../../static/img/landing/social/fb.svg";
import YoutubeSVG from "../../static/img/landing/social/youtube.svg";
import OkSVG from "../../static/img/landing/social/ok.svg";
import TwitterSVG from "../../static/img/landing/social/tw.svg";
import LogoMobSVG from "../../static/img/landing/logoHeaderMob.svg";

export default class LandingFormPageLayout extends React.PureComponent {
  render() {
    const { onlyChildren, whiteBg } = this.props;
    return (
      <div
        className={cn({
          [cl.whiteBg]: whiteBg
        })}
      >
        <div
          className={cn({
            [cl.onlyChildContainer]: onlyChildren,
            [cl.container]: !onlyChildren,
            [cl.whiteBg]: whiteBg
          })}
        >
          {!onlyChildren && (
            <header
              className={`${cl.header} flex-align-center justify-between`}
            >
              <Link to="/" className={cl.logoPostion}>
                <div className={`${cl.logoWrap} flex align-center`}>
                  <a href="https://tenderplan.ru/app">
                    <img
                      className={cl.logo}
                      src="/public/app/img/landing/mainLogo.svg"
                    />
                    <LogoMobSVG className={cl.mobileLogo} />
                  </a>
                </div>
              </Link>

              <div
                className={`${cl.contacts} flex-align-center justify-between`}
              >
                <a className={cl.contactsItem} href="tel:+78003334505">
                  8 800 333-45-05
                </a>

                <a className={cl.contactsItem} href="mailto:info@tenderplan.ru">
                  info@tenderplan.ru
                </a>
              </div>
            </header>
          )}

          {this.props.children}

          {!onlyChildren && (
            <footer className={cl.footer}>
              <div
                className={`${
                  cl.footerContacts
                } flex-align-center justify-between`}
              >
                <a className={cl.footerContactsItem} href="tel:+78003334505">
                  8 800 333-45-05
                </a>

                <a
                  className={cl.footerContactsItem}
                  href="mailto:info@tenderplan.ru"
                >
                  info@tenderplan.ru
                </a>
              </div>

              <div className={cl.footerSocial}>
                <ul className={`${cl.socialList} flex justify-between`}>
                  <li className={cl.socialItem}>
                    <a
                      className={`${cl.socialLink} flex-position-center`}
                      href="https://vk.com/tenderplan"
                      target="_blank"
                      rel="noopener"
                    >
                      <VkSVG />
                    </a>
                  </li>
                  {/* <li className={cl.socialItem}>
                    <a
                      className={`${cl.socialLink} flex-position-center`}
                      href="https://www.instagram.com/tenderplan.ru/"
                      target="_blank"
                      rel="noopener"
                    >
                      <InstagramSVG />
                    </a>
                  </li>
                  <li className={cl.socialItem}>
                    <a
                      className={`${cl.socialLink} flex-position-center`}
                      href="https://www.facebook.com/tenderplan/"
                      target="_blank"
                      rel="noopener"
                    >
                      <FacebookSVG />
                    </a>
                  </li> */}
                  <li className={cl.socialItem}>
                    <a
                      className={`${cl.socialLink} flex-position-center`}
                      href="https://www.youtube.com/channel/UC5ohzfKfpdI9O4k9iVexd1g/featured?view_as=subscriber"
                      target="_blank"
                      rel="noopener"
                    >
                      <YoutubeSVG />
                    </a>
                  </li>
                  <li className={cl.socialItem}>
                    <a
                      className={`${cl.socialLink} flex-position-center`}
                      href="https://ok.ru/group/54521546932367"
                      target="_blank"
                      rel="noopener"
                    >
                      <OkSVG />
                    </a>
                  </li>
                  <li className={cl.socialItem}>
                    <a
                      className={`${cl.socialLink} flex-position-center`}
                      href="https://twitter.com/tenderplan"
                      target="_blank"
                      rel="noopener"
                    >
                      <TwitterSVG />
                    </a>
                  </li>
                </ul>
              </div>
            </footer>
          )}
        </div>
      </div>
    );
  }
}
