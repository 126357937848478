// import moment from 'moment';
if (!__SERVER__) {
  if (!("remove" in Element.prototype)) {
    Element.prototype.remove = function() {
      if (this.parentNode) {
        this.parentNode.removeChild(this);
      }
    };
  }
}

//replace all
String.prototype.replaceAll = function(search, replacement) {
  const target = this;
  return target.split(search).join(replacement);
};

export const gridLayout = (items, props) => {
  const { columns, columnWidth, gutterWidth, gutterHeight } = props;

  const columnHeights = [];
  for (let i = 0; i < columns; i++) {
    columnHeights.push(0);
  }
  const positions = items.map((itemProps, i) => {
    // const column = columnHeights.indexOf(Math.min.apply(null, columnHeights));
    const column = i % columns;

    // const height = itemProps.itemHeight || (itemProps.itemRect && itemProps.itemRect.height);
    let paddingTop = 0,
      paddingBottom = 0,
      itemHeight = itemProps["data-height"] || 0;
    if (itemProps.style) {
      paddingTop = itemProps.style.paddingTop || 0;
      paddingTop = itemProps.style.paddingBottom || 0;
    }
    const height = itemHeight + paddingTop + paddingBottom;
    if (!(height && typeof height === "number")) {
      throw new Error(
        'Each child must have an "itemHeight" prop or an "itemRect.height" prop.'
      );
    }

    const x = column * columnWidth + column * gutterWidth;
    const y = columnHeights[column];

    columnHeights[column] += Math.round(height) + gutterHeight;

    return [x, y];
  });

  const gridWidth = columns * columnWidth + (columns - 1) * gutterWidth;
  const gridHeight = Math.max.apply(null, columnHeights) - gutterHeight;

  return { positions, gridWidth, gridHeight };
};

export const logo = "/public/app/img/logo.svg";

export const formatUserPhotoPath = path => {
  if (path.toLowerCase().indexOf("blob") !== -1) {
    return path;
  }

  // if (__DESKTOP__){
  //   return 'https://tenderplan.ru/media/photo/' + path;
  // }

  return "/media/photo/" + path;
};

/**
 * generates unique id
 * @return {string} random string made of numbers and starting from '_'
 */
export const generateUniqueId = () =>
  "_" +
  Math.random()
    .toString(36)
    .substr(2, 9);

export function getScrollbarWidth() {
  if (__SERVER__) return 17;
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.width = "100px";
  outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = "scroll";

  // add innerdiv
  const inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
}

Array.prototype.distinct = function() {
  const keys = {},
    result = [];
  for (let i = 0, l = this.length; i < l; ++i) {
    const item = this[i];
    if (keys.hasOwnProperty(item)) {
      continue;
    }
    result.push(item);
    keys[item] = true;
  }
  return result;
};

/**
 * [debounce description]
 * @param  {function} func      [function to call]
 * @param  {int} wait      [milliseconds to wait]
 * @param  {bool} immediate [is func should be called immediately]
 */
export function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    let context = this,
      args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

// pluralize function
const plural = a => {
  if (a % 10 === 1 && a % 100 !== 11) return 0;
  else if (a % 10 >= 2 && a % 10 <= 4 && (a % 100 < 10 || a % 100 >= 20))
    return 1;
  return 2;
};

export const pluralize = (i, str1, str2, str3) => {
  i = i >= 0 ? i : -i;

  switch (plural(i)) {
    case 0:
      return str1;
    case 1:
      return str2;
    default:
      return str3;
  }
};

// formats money
// removeZeroes - pass true to remove fraction part
export const formatMoney = (value, removeZeroes, removeKopeck) => {
  value = value || 0;
  let result = parseFloat(value)
    .toFixed(removeKopeck ? 0 : 2)
    .replace(/./g, (c, i, a) => {
      return i && c !== "." && (a.length - i) % 3 === 0 ? " " + c : c;
    })
    .replace(".", ",");
  if (removeZeroes || removeKopeck) {
    result = result.replace(",00", "");
  }
  return result;
};

const toKey = (value, keys) => {
  const keyValues = [];
  // получаем массив значений по указанным ключам
  for (let j = 0; j < keys.length; j++) {
    keyValues.push(value[keys[j]]);
  }
  return keyValues.join("_");
};

export const toDictionary = (array, keys) => {
  const dictionary = {};
  // проходим по каждому элементу
  for (let i = 0; i < array.length; i++) {
    const value = array[i];

    const key = toKey(value, keys);
    // проверяем нет ли записи по указанному ключу
    const notification = dictionary[key];
    // если есть - заменяем, при этом добавляем замененной модели атрибут IsCopy
    if (notification !== undefined) {
      notification.IsCopy = true;
    }
    // заносим значение в словарь
    dictionary[key] = value;
  }
  return dictionary;
};

export const toArray = array => {
  return array === null ? array : Array.isArray(array) ? array : [array];
};

// функция, определения символа валюты по названию/коду валюты
export const getCurrency = (name, nope) => {
  const lowercase = name.toLowerCase();
  if (
    lowercase.indexOf("российский рубль") !== -1 ||
    lowercase.indexOf("rub") !== -1
  ) {
    return "₽";
  } else if (
    lowercase.indexOf("доллар сша") !== -1 ||
    lowercase.indexOf("usd") !== -1
  ) {
    return "$";
  } else if (
    lowercase.indexOf("евро") !== -1 ||
    lowercase.indexOf("eur") !== -1
  ) {
    return "€";
  }
  return nope ? "" : name;
};

export const twoDigits = value => {
  if (value === undefined) {
    return value;
  }
  let minus = false;
  if (value < 0) {
    minus = true;
    value = -value;
  }
  value = value.toString();

  return value[1] ? value : minus ? "-0" + value[0] : "0" + value[0];
};

// функция, возращающая глубокую копию объекта
export const deepCopyObject = obj => JSON.parse(JSON.stringify(obj));

// функция, возвращающая массив позиций символа в строке
export const getCharIndexArr = (str, char) => {
  const charArr = [];

  let charIndex = str.indexOf(char);
  if (charIndex !== -1) {
    do {
      charArr.push(charIndex);
      charIndex = str.indexOf(char, charIndex + 1);
    } while (charIndex !== -1);
  }

  return charArr.sort((a, b) => {
    return a - b;
  });
};

// функция, разбивающая строку wordString на массив
// splitterIndexArr - массив позиций элементов строки, по которым она разбивается
const splitWordString = (wordString, splitterIndexArr) => {
  const splittedArr = [];

  let keyWordStartIndex = 0;
  let shift = 0;
  let keyWord = "";
  for (let i = 0; i < splitterIndexArr.length; i++) {
    keyWord = wordString.slice(
      keyWordStartIndex - shift - (i > 1 ? i - 1 : 0),
      splitterIndexArr[i] - shift - (i > 0 ? i : 0)
    );
    wordString = wordString.slice(
      splitterIndexArr[i] - shift - (i > 0 ? i : 0) + 1,
      wordString.length
    );
    shift += keyWord.length;

    keyWordStartIndex = splitterIndexArr[i];
    if (keyWord.trim()) {
      splittedArr.push(keyWord.trim());
    }
  }

  if (wordString.trim()) {
    splittedArr.push(wordString.trim());
  }

  return splittedArr;
};

// функция, разбивающая строку на слова по запятым
export const parseWordString = keyWordStr => {
  const quoteArr = getCharIndexArr(keyWordStr, '"');
  const commaArr = getCharIndexArr(keyWordStr, ",");

  const parsedArr = !commaArr.length
    ? [keyWordStr]
    : splitWordString(
        keyWordStr,
        (function() {
          if (!quoteArr.length) {
            return commaArr;
          }

          if (
            quoteArr.length % 2 !== 0 &&
            !quoteArr.includes(keyWordStr.length - 1)
          ) {
            quoteArr.push(keyWordStr.length - 1);
          }

          const Areas = [];
          for (let i = 0; i < quoteArr.length; i += 2) {
            Areas.push({
              AreaStart: quoteArr[i],
              AreaEnd: i + 1 < quoteArr.length ? quoteArr[i + 1] : quoteArr[i]
            });
          }

          const commaSplitterArr = [];
          for (let i = 0; i < commaArr.length; i++) {
            let isSplitter = true;

            for (let j = 0; j < Areas.length; j++) {
              if (
                commaArr[i] >= Areas[j].AreaStart &&
                commaArr[i] <= Areas[j].AreaEnd
              ) {
                isSplitter = false;
              }
            }

            if (isSplitter) {
              commaSplitterArr.push(commaArr[i]);
            }
          }

          return commaSplitterArr;
        })()
      );

  return parsedArr;
};

//ВРАЧ -> Врач. форматирует строку, где первая заглавная, остальные в нижнем регистре
export const strFormatting = param => {
  const str = param.toLowerCase();
  return param.charAt(0).toUpperCase() + str.substr(1);
};

export const getDigitalRank = (value, fixed, isObj, isFormatMoney) => {
  if (!value) return isObj ? { value, rank: false } : value;

  let rank = 0,
    v = value;

  if ((!fixed || fixed === 0) && v.toFixed(0) === "1000000") {
    // исключение для value 999999.9
    rank = 1;
    v = 1;
  } else if ((!fixed || fixed === 0) && v.toFixed(0) === "1000000000") {
    // исключение для value 999999999.9
    rank = 2;
    v = 1;
  } else if (v >= 1000000) {
    v = v / 1000;
    while (v >= 1000 && rank < 2) {
      v = v / 1000;
      rank += 1;
    }
  }

  switch (rank) {
    case 0:
      return isObj
        ? {
            value: isFormatMoney
              ? formatMoney(v.toFixed(fixed || 0), true)
              : v.toFixed(fixed || 0),
            rank: false
          }
        : isFormatMoney
        ? formatMoney(v.toFixed(fixed || 0), true)
        : v.toFixed(fixed || 0);
    case 1:
      return isObj
        ? {
            value: isFormatMoney
              ? formatMoney(v.toFixed(fixed || 0), true)
              : v.toFixed(fixed || 0),
            rank: " млн"
          }
        : isFormatMoney
        ? formatMoney(v.toFixed(fixed || 0), true) + " млн"
        : v.toFixed(fixed || 0) + " млн";
    case 2:
      return isObj
        ? {
            value: isFormatMoney
              ? formatMoney(v.toFixed(fixed || 0), true)
              : v.toFixed(fixed || 0),
            rank: " млрд"
          }
        : isFormatMoney
        ? formatMoney(v.toFixed(fixed || 0), true) + " млрд"
        : v.toFixed(fixed || 0) + " млрд";
    default:
      return isObj
        ? {
            value: isFormatMoney
              ? formatMoney(v.toFixed(fixed || 0), true)
              : v.toFixed(fixed || 0),
            rank: false
          }
        : isFormatMoney
        ? formatMoney(v.toFixed(fixed || 0), true)
        : v.toFixed(fixed || 0);
  }
};

export const isVisibleOnScreen = (rect, view = 0, threshold = 0) => {
  if (!rect) return false;

  const below = rect.top - view + threshold >= 0;
  return !below;
};

export const toFixedValue = value => {
  if (!value) return value;

  return value
    .toFixed(1)
    .replace(".00", "")
    .replace(".0", "")
    .replace(".", ",");
};

export const toRangeValue = (value, threshold = 0.1) => {
  if (!value) return value;

  const lowerBound = Math.floor(value);
  if (value - lowerBound < threshold) {
    return lowerBound.toString();
  }

  const upperBound = Math.ceil(value);
  return `${lowerBound}-${upperBound}`;
};

export const getDigitalRankChart = (value, format) => {
  if (!value) return value;

  let rank = 0,
    v = value;

  if (v.toFixed(0) === "1000000") {
    // исключение для value 999999.9
    rank = 1;
    v = 1;
  } else if (v.toFixed(0) === "1000000000") {
    // исключение для value 999999999.9
    rank = 2;
    v = 1;
  } else if (v >= 1000000) {
    v = v / 1000;
    while (v >= 1000 && rank < 2) {
      v = v / 1000;
      rank += 1;
    }
  }

  switch (rank) {
    case 0:
      return v + " тыс";
    case 1:
      return v + " млн";
    case 2:
      return v + " млрд";
    default:
      return v;
  }
};

export const checkInn = inn => {
  if (!inn || inn.match(/\D/) || inn === "0000000000" || inn === "0000000000") {
    return false; // check non-digit chars in string
  }
  const arr = inn.match(/(\d)/g);

  if (arr.length === 10) {
    return (
      arr[9] ===
      String(
        ((2 * arr[0] +
          4 * arr[1] +
          10 * arr[2] +
          3 * arr[3] +
          5 * arr[4] +
          9 * arr[5] +
          4 * arr[6] +
          6 * arr[7] +
          8 * arr[8]) %
          11) %
          10
      )
    );
  } else if (arr.length === 12) {
    return (
      arr[10] ===
        String(
          ((7 * arr[0] +
            2 * arr[1] +
            4 * arr[2] +
            10 * arr[3] +
            3 * arr[4] +
            5 * arr[5] +
            9 * arr[6] +
            4 * arr[7] +
            6 * arr[8] +
            8 * arr[9]) %
            11) %
            10
        ) &&
      arr[11] ===
        String(
          ((3 * inn[0] +
            7 * arr[1] +
            2 * arr[2] +
            4 * inn[3] +
            10 * arr[4] +
            3 * arr[5] +
            5 * inn[6] +
            9 * arr[7] +
            4 * arr[8] +
            6 * inn[9] +
            8 * inn[10]) %
            11) %
            10
        )
    );
  }

  return false;
};
