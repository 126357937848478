let CustomerContainer;

const getComponentAsync = async store => {
  const { injectReducer } = await import("../../../store/reducers");

  CustomerContainer =
    CustomerContainer || (await import("../../Customer")).default;

  injectReducer(store, {
    key: "customers",
    reducer: (await import("@tenderplan3/store/modules/customers")).default
  });
  injectReducer(store, {
    key: "organizations",
    reducer: (await import("@tenderplan3/store/modules/organizations")).default
  });
  injectReducer(store, {
    key: "participants",
    reducer: (await import("@tenderplan3/store/modules/participants")).default
  });

  return CustomerContainer;
};

export default store => ({
  path: ":customer",
  getComponent(nextState, cb) {
    getComponentAsync(store).then(res => {
      cb(null, res);
    });
  }
});
